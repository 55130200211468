/* ----------------------------------------------------------------------------
 * Functions and mixins
 * ------------------------------------------------------------------------- */

 @function rem($pixels){
    @return $pixels / 16 + rem;
}

@mixin transition($args...) {
    transition: $args;
}