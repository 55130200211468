.admin-bar .navbar {
    top: 32px;
}

.navbar {
    padding: 0 !important;
    background: #fff;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    .container {
        padding-left: rem(30) !important;
		padding-right: rem(30) !important;
		@include md-min {
			padding-right: rem(50) !important;
			padding-left: rem(50) !important;
		}
        max-width: 100%;
    }
    .navbar-brand {
        &.minimze {
            svg .logo-icon {
                opacity: 0;
            }
        }
        svg {
            width: 195px;
            height: 66px;
            @include md-max {
                width: 152px;
                height: 52px
            }
            @include lg-min {
                width: 169px;
                height: 57px;
            }
            .logo-icon {
                opacity: 1;
                @include transition(all .3s ease);
                transform-origin: center;
                @include md-max {
                    display: none;
                }
            }
        }
    }
    .navbar-share {
        font-size: rem(24);
        color: $primary;
    }
    &-nav {
        li {
            a {
                color: #000;
                line-height: 75px;
                font-size: 15px;
                padding-top: 0;
                padding-bottom: 0;
                &:hover,
                &:focus {
                    color: $link-hover;
                }
            }
        }
    }
}