.single__content {
    margin-bottom: rem(60);

    .container {
        padding-right: rem(15);
        padding-left: rem(15);
    }
    h2,
    h3,
    h4 {
        font-weight: 700;
        margin-top: rem(36);
        font-size: rem(20);
    }

    a {
        text-decoration: underline;
    }

    hr {
        background: #E8E8E8;
        margin: rem(8) 0;
    }

    ul {
        margin-top: rem(16);

        li {
            margin-bottom: rem(16);
            i {
                color: $gray-medium;
                font-size: rem(22);
            }
        }
    }
}