@font-face {
    font-family: 'ProximaNova';
    font-weight: 400;
    font-display: swap;
    src: url('../../../fonts/proximanova/regular/ProximaNova-Reg-webfont.eot');
    src: url('../../../fonts/proximanova/regular/ProximaNova-Reg-webfont.eot?iefix') format('eot'),
    url('../../../fonts/proximanova/regular/ProximaNova-Reg-webfont.woff') format('woff'),
    url('../../../fonts/proximanova/regular/ProximaNova-Reg-webfont.ttf') format('truetype'),
    url('../../../fonts/proximanova/regular/ProximaNova-Reg-webfont.svg#ProximaNova-Reg-webfont') format('svg')
}

@font-face {
    font-family: 'ProximaNova';
    font-weight: 300;
    font-display: swap;
    src: url('../../../fonts/proximanova/light/ProximaNova-Light-webfont.eot');
    src: url('../../../fonts/proximanova/light/ProximaNova-Light-webfont.eot?iefix') format('eot'),
    url('../../../fonts/proximanova/light/ProximaNova-Light-webfont.woff') format('woff'),
    url('../../../fonts/proximanova/light/ProximaNova-Light-webfont.ttf') format('truetype'),
    url('../../../fonts/proximanova/light/ProximaNova-Light-webfont.svg#ProximaNova-Light-webfont') format('svg')
}

@font-face {
    font-family: 'ProximaNova';
    font-weight: 700;
    font-display: swap;
    src: url('../../../fonts/proximanova/bold/ProximaNova-Bold-webfont.eot');
    src: url('../../../fonts/proximanova/bold/ProximaNova-Bold-webfont.eot?iefix') format('eot'),
    url('../../../fonts/proximanova/bold/ProximaNova-Bold-webfont.woff') format('woff'),
    url('../../../fonts/proximanova/bold/ProximaNova-Bold-webfont.ttf') format('truetype'),
    url('../../../fonts/proximanova/bold/ProximaNova-Bold-webfont.svg') format('svg')
}

$font-size-base:    14px;

$font-size-h1:      floor(($font-size-base * 2.6)); // ~36px
$font-size-h4:      ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:      $font-size-base; // 14px
$font-size-h6:      ceil(($font-size-base * 0.85)); // ~12px

html,
body {
    font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: #333;
    line-height: 1.71;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 300;
    word-break: break-word;
}

h1 {
    font-size: $font-size-h1;
    font-weight: 700;
    margin-bottom: rem(30);
}

h2 {
        font-size: floor((14px * 2.15));
    margin-bottom: rem(20);
}

h3 {
    font-size: floor((14px * 1.7));
    margin-bottom: rem(30);
}

h4 {
    font-size: $font-size-h4;
    margin-bottom: rem(30);
}

h5 {
    font-size: $font-size-h5;
    font-weight: 700;
    margin-bottom: rem(20);
}

a {
    @include transition(all .3s ease);
    color: $link;
    &:hover,
    &:active,
    &:focus {
        color: $link-hover;
    }
}

ul, ol {
    padding-left: rem(20);
}