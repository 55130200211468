/* ----------------------------------------------------------------------------
 * Buttons
 * ------------------------------------------------------------------------- */

.btn {
    padding: rem(16);
    margin-top: rem(30);
    border-radius: 0;
    font-weight: bold;
    color: #fff !important;
    text-decoration: none !important;
    &:hover,
    &:focus,
    &:active {
        outline: none;
    }
    i {
        margin-left: rem(10);
    }
    &-cta {
        width: 100%;
        margin-top: rem(16);
        border: none;
        color: #000 !important;
        text-align: left;
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include transition(all .3s ease);
        &:hover,
        &:focus,
        &:active {
            color: #fff !important;
            background: $primary !important;
        }

        &-primary {
            color: #fff !important;
            background: $primary !important;
            &:hover,
            &:focus,
            &:active {
                color: #fff !important;
                background: $brown !important;
            }
        }
    }
}