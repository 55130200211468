section {
    padding: rem(70) 0;
}

.container-fluid {
    padding-left: rem(30);
    padding-right: rem(30);
    @include md-min {
        padding-right: rem(50);
        padding-left: rem(50);
    }
    @include lg-min {
        max-width: 90vw;
        padding-right: 15px;
        padding-left: 15px;
    }
    @include xxl-min {
        max-width: 75vw;
    }
}

.alignleft {
    text-align: left;
}

.aligncenter {
    text-align: center;
    margin: 0 auto;
}

.alignright {
    text-align: right;
}