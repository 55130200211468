.header {
	height: 100%;
	position: relative;
	& > .container {
		padding-left: rem(30);
		padding-right: rem(30);
		@include md-min {
			padding-right: rem(50);
			padding-left: rem(50);
		}
		@include lg-min {
			padding-right: rem(15);
			padding-left: rem(15);
		}
	}
	padding-top: rem(50);
	&__inner {
		z-index: 10;
		padding-top: rem(90);
		margin-bottom: rem(30);
		h1 {
			color: #fff;
			font-size: rem(40);
			line-height: 1.14;
			font-weight: bold;
			margin-bottom: rem(15);
			@include lg-min {
				font-size: rem(52);
			}
		}
		p {
			color: #fff;
			line-height: 1.71;
			margin-bottom: rem(30);
		}
	}
	&::before {
		content: '';
		height: 60%;
		@include md-min {
			height: rem(700);
		}
		@include xxl-min {
			height: rem(600);
		}
        background-color: $primary;
		position: absolute;
    	width: 100%;
	}
	&__post {
		z-index: 10;
		@include lg-min {
			padding-top: rem(20);
		}
		position: relative;
		display: flex;
		&-image {
			background: #f2f2f2;
            img {
				height: auto;
				width: 100%;
				object-fit: cover;
				object-position: center;
				padding: rem(30);
				@include lg-min {
					padding: rem(30) rem(100);
				}
			}
		}
	}
}